import React from 'react';
import PropTypes from 'prop-types';
import styles from './LoadingBubbles.module.scss';

const LoadingBubbles = ({ size = 'medium', color = 'default' }) => {
  const sizeClass = styles[size] || styles.medium;
  const colorClass = styles[color] || styles.default;

  return (
    <div className={`${styles.spinner} ${sizeClass} ${colorClass}`}>
      <div className={styles.bounce1}></div>
      <div className={styles.bounce2}></div>
      <div className={styles.bounce3}></div>
    </div>
  );
};

LoadingBubbles.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['primary', 'secondary', 'white']),
};

export default LoadingBubbles;
