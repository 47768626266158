import React from 'react';
import styles from './Redirecting.module.scss';
import LoadingBubbles from 'components/LoadingBubbles/LoadingBubbles';

const Redirecting = () => {
  return (
    <div className={styles.redirectingContainer}>
      <div className={styles.content}>
        <h1>Redirecting you...</h1>
        <LoadingBubbles size="large" />
      </div>
    </div>
  );
};

export default Redirecting;
