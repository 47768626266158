import loadable from '@loadable/component';

const Aup = loadable(() => import('./Terms/Aup'));

const AnswerPublicQuestion = loadable(() =>
  import('./AnswerPublicQuestion/AnswerPublicQuestion')
);

const AuthCode = loadable(() => import('./AuthCode/AuthCode'));

const BillContainer = loadable(() => import('./Bills/Bill'));

const BriefDetailsClient = loadable(() =>
  import('./BriefDetailsClient/BriefDetailsClient')
);

const Copyright = loadable(() => import('./Copyright/Copyright'));

const EngagementTerms = loadable(() => import('./Terms/EngagementTerms'));

const HowItWorks = loadable(() => import('./HowItWorks/HowItWorks'));

const ManageBriefs = loadable(() => import('./ManageBriefs/ManageBriefs'));

const ManagePitches = loadable(() => import('./ManagePitches/ManagePitches'));

const MicroProjects = loadable(() => import('./MicroProjects/MicroProjects'));

const Messages = loadable(() => import('./Messages/Messages'));

const NotFound = loadable(() => import('./NotFound/NotFound'));

const Notifications = loadable(() => import('./Notifications/Notifications'));

const Onboarding = loadable(() => import('./Onboarding/Onboarding'));

const OnboardingNotifications = loadable(() =>
  import('./OnboardingNotifications/OnboardingNotifications')
);

const PlatformTerms = loadable(() => import('./Terms/PlatformTerms'));

const Pricing = loadable(() => import('./Pricing/Pricing'));

const Post = loadable(() => import('./Post/Post'));

const ReportMessages = loadable(() => import('./Reports/ReportMessages'));

const SignUp = loadable(() => import('./SignUp/SignUp'));

const SignIn = loadable(() => import('./SignIn/SignIn'));

const SimplePayment = loadable(() => import('./Payments/SimplePayment'));

const Shortlist = loadable(() => import('./Shortlist/Shortlist'));

const Undock = loadable(() => import('./Undock/Undock'));

const ViewEarnings = loadable(() => import('./ViewEarnings/ViewEarnings'));

const ViewPayments = loadable(() => import('./ViewPayments/ViewPayments'));

export {
  AnswerPublicQuestion,
  AuthCode,
  Aup,
  BillContainer,
  BriefDetailsClient,
  Copyright,
  EngagementTerms,
  HowItWorks,
  ManageBriefs,
  ManagePitches,
  MicroProjects,
  Messages,
  NotFound,
  Notifications,
  Onboarding,
  OnboardingNotifications,
  PlatformTerms,
  Post,
  Pricing,
  ReportMessages,
  Shortlist,
  SignUp,
  SignIn,
  SimplePayment,
  Undock,
  ViewEarnings,
  ViewPayments,
};
